import {
  ProductVariantCalculator,
  IProductSelectionAvailabilityMap,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/ProductVariantCalculator';
import {IProduct} from '@wix/wixstores-graphql-schema';

export class ProductsOptionsService {
  private readonly productsMap: {
    [productId: string]: {
      variantCalculator: ProductVariantCalculator;
    };
  } = {};

  public addProducts(products: IProduct[]): void {
    products.forEach((product) => {
      if (this.productsMap[product.id]) {
        const prevSelectionIds = this.productsMap[product.id].variantCalculator.getVariantSelectionIds();
        this.productsMap[product.id].variantCalculator = new ProductVariantCalculator(product);
        this.productsMap[product.id].variantCalculator.setVariantSelectionIds(prevSelectionIds as number[]);
        return;
      }

      this.productsMap[product.id] = {
        variantCalculator: new ProductVariantCalculator(product),
      };
    });
  }

  public handleUserInput(productId: string, selectionIds: number[]): void {
    this.productsMap[productId].variantCalculator.setVariantSelectionIds(selectionIds);
  }

  public getVariantsAvailabilityMap(): {
    [productId: string]: IProductSelectionAvailabilityMap;
  } {
    return Object.keys(this.productsMap).reduce(
      (result, productId) => ({
        ...result,
        [productId]: this.productsMap[productId].variantCalculator.getSelectionsAvailability(),
      }),
      {}
    );
  }
}
